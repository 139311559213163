<template>
  <div class="baseinput mainbgchange" :class="{'cursor-pointer': sourceValue === true}">
    <label class="labelstyle" :class="{'cursor-pointer': sourceValue === true}">
      {{ label }}
      <sup v-if="requiredname === 'required' || requiredname === 'Required'">
        <i class="fa fa-asterisk imp px-1"></i>
      </sup>
    </label>

    <div class="input-container w-100">
      <Flatpickr 
        :disabled="privilege === 'View' || fromarchive === 'true' || fielddisabled==true"
        v-model="selectedTimeComputed"
        class="form-control bg-white position-relative"
        :placeholder="timeFormat"
        :config="flatpickrConfig"
        @blur="$emit('focusoutfun')"
      />
      <button
        v-if="selectedTimeComputed"
        type="button"
        class="clear-btn"
        @click="clearField"
        aria-label="Clear field"
      >
        ✖
      </button>
    </div>
  </div>
</template>


<script>
export default {
props:{
  timeFormat: {
    type: String,
    default: 'h:mm A',
  },
  fielddisabled:{
        type: Boolean,
        default: false
    },
  sourceValue:{
        type: Boolean,
        default: false
    },
    label:{
        type: String,
        default: ''
    },
    modelValue:{
        type:[String, Number],
        default: ''
    },
      classname:{
        type: String,
        default: 'form-control'
      },
      requiredname:{
        type: String,
        default: ''
      },
      privilege:{
        type: String,
        default: ''
      },
       fromarchive: {
        type: String,
        default: 'false'
      }
  },
  async  mounted() {
    this.flatpickrConfig.dateFormat = this.flatpickrTimeFormatMap[this.timeFormat] || 'H:i';
    this.selectedTime = this.modelValue;
  },
  computed: {
  selectedTimeComputed: {
    get() {
      return this.modelValue;
    },
    set(value) {
      this.$emit('update:modelValue', value);
      this.$emit('focusoutfun');
    },
  },
},
watch: {
  timeFormat: {
    immediate: true,
    handler() {
      this.updateFlatpickrConfig();
      // this.flatpickrConfig.dateFormat = this.flatpickrTimeFormatMap[newFormat] || 'H:i';
    },
  },
},
  data() {
    return {
      flatpickrTimeFormatMap: {
        'h:mm A': 'h:i K', // 12-hour time with AM/PM
        'HH:mm': 'H:i',    // 24-hour time
        'h:mm': 'h:i',     // 12-hour time without AM/PM
        'HH:mm:ss': 'H:i:S', // 24-hour time with seconds
        'hh:mm:ss tt':'h:i:S K'
      },
      selectedTime:null,
      date: null,
      flatpickrConfig: {
        enableTime: true,
        noCalendar: true, // Time picker only
        dateFormat: '',
        time_24hr: false, // Default to 12-hour format
      },
    }
  },
  methods: {
    clearField() {
    this.selectedTimeComputed = null; // Reset the field
  },
    updateTime(selectedTime) {
  this.selectedTime = selectedTime;
  this.$emit('update:modelValue', selectedTime);
},
    testfun(){
      this.$emit('update:modelValue', this.selectedTime);
    },
    updateFlatpickrConfig() {
      this.flatpickrConfig.dateFormat =
        this.flatpickrTimeFormatMap[this.timeFormat] || 'H:i';
      this.flatpickrConfig.time_24hr =
        this.timeFormat.startsWith('HH') || this.timeFormat.includes('24'); // Check for 24-hour format
    },
},
}
</script>

<style scoped>
.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.clear-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 10px;
  cursor: pointer;
  color: #999;
}

.clear-btn:hover {
  color: #333;
}
.active {
    opacity: 1;
    pointer-events: auto;
    position: relative !important;
}
.baseinput{
  display: flex;
flex-direction: column;
align-items: flex-start;
background-color:#ffffff;
}
.labelstyle{
  font-size: 14px;
  margin-bottom: 0.1rem !important;
  font-weight: 600;
}
.minwidth{
  min-width: max-content;
}
.errorlist{
  border-width: 2px !important;
  border-color: red !important;
}
.hightlight{
  /* border-color: red !important; */
  border-bottom: 6px solid #f8ac59;
  
}
.imp {
    color: #ff0000;
    font-size: 7px;
}
.borderValidated {
  border:2px solid green!important;
}
.borderIncorrect{
   border:2px solid orange!important;
}
.borderFail{
   border:2px solid red!important;
}
</style>
