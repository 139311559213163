/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
export default {
    name: "mapProgrammePopup",
    components: {
    },
    props: {
        reportList: {
            type: Object,
            default: () => {}
        },
        TocId: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            baseUrl: process.env.VUE_APP_Service_URL,
            programme: [],
            studyInfo: {},
            reportName: "",
            reportId: "",
            mapData: {
                StudyId: "",
                StudyName: "",
                StudyRef: "",
                TocId: "",
                ReportId: "",
                ReportName: "",
                Version: "",
                TocDescription: "",
                DeveloperName: "",
                QCName: "",
                Status: "",
                ProgramId: ""
            },

        }
    },
    mounted() {
        console.log("Values toc",this.TocId)
        console.log("Values child",this.reportList)
        this.getProgram();
        this.getstudyinfo()
    },
    methods: {
        async mapProgramme(pgm) {
            console.log("pgm", this.reportList)
            this.studyId = store.getters.getStudyIs;
            const idtoken = store.getters.getIdToken;
            this.mapData.ProgramId = pgm._id;
            this.mapData.StudyId = this.studyId;
            this.mapData.ReportId = this.reportList.Reportid;
            this.mapData.ReportName = this.reportList.Reportname;
            this.mapData.Version = "1.0";
            this.mapData.Status = "In Development";
            this.mapData.StudyName = this.studyInfo.studyName;
            this.mapData.StudyRef = this.studyInfo.studyRef;
            this.mapData.TocId = this.TocId;
            try {
                const res = await axios.post(
                    `${this.baseUrl}/stats-python/programme/mapprogram`, this.mapData,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                );

                this.files = res.data;
            } catch (err) {
                console.error("Error fetching files:", err);
            }
        },
        async closeModal() {
            this.$emit("closeModal")
        },
        async getstudyinfo() {
            const idtoken = store.getters.getIdToken;
            await axios
                .get(
                    `${this.baseUrl}/management/study/getbyid/${store.getters.getStudyIs}?api-version=1.0`,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    this.studyInfo = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async getProgram() {
            const idtoken = store.getters.getIdToken;
            try {
                const res = await axios.get(
                    `${this.baseUrl}/stats-python/programme/programs?Name=d`,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                );

                this.programme = res.data;
                console.log("programme", this.programme)
            } catch (err) {
                console.error("Error fetching files:", err);
            }
        },

    }
}
