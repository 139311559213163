<template>
  <div class="baseinput mainbgchange" :class="{'cursor-pointer': sourceValue === true}">
    <label class="labelstyle" :class="{'cursor-pointer': sourceValue === true}">
      {{ label }}
      <sup v-if="requiredname === 'required' || requiredname === 'Required'">
        <i class="fa fa-asterisk imp px-1"></i>
      </sup>
    </label>
    <!-- Debug display to verify the three values -->
    
    <Flatpickr
      :disabled="privilege === 'View' || fromarchive === 'true' || fielddisabled === true"
      v-model="selectedDate"
      class="form-control bg-white position-relative"
      :placeholder="dateFormat"
      :config="flatpickrConfig"
      @blur="$emit('focusoutfun')"
    />
  </div>
</template>

<script>
export default {
  props: {
    dateFormat: {
      type: String,
      default: 'DD-MM-YYYY', // e.g., "DD-MM-YYYY"
    },
    fielddisabled: {
      type: Boolean,
      default: false
    },
    sourceValue: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    classname: {
      type: String,
      default: 'form-control'
    },
    requiredname: {
      type: String,
      default: ''
    },
    privilege: {
      type: String,
      default: ''
    },
    fromarchive: {
      type: String,
      default: 'false'
    }
  },

  data() {
    return {
      // Map from your "friendly" dateFormat -> Flatpickr-compatible tokens.
      flatpickrFormatMap: {
        'DD/MM/YYYY': 'd/m/Y',
        'MM-DD-YYYY': 'm-d-Y',
        'MM/DD/YYYY': 'm/d/Y',
        'DD-MM-YYYY': 'd-m-Y',
        'YYYY-MM-DD': 'Y-m-d',
        'YYYY.MM.DD': 'Y.m.d',
        'DD.MMM.YYYY': 'd.M.Y',
        'DD.MM.YYYY': 'd.m.Y',
        'YYYY/MM/DD': 'Y/m/d',
        'YYYY.DD.MM': 'Y.d.m',
        'DD/MMM/YYYY': 'd/M/Y',
        'DD-MMM-YYYY': 'd-M-Y',
      },

      // This will be the actual value bound to Flatpickr via v-model
      selectedDate: null
    };
  },

  created() {
    // Ensure 'selectedDate' is set before Flatpickr is created
    this.selectedDate = this.modelValue;
  },

  computed: {
    // Always compute the final Flatpickr config 
    // so it's ready at component initialization.
    flatpickrConfig() {
      return {
        // Map your custom dateFormat ("DD-MM-YYYY") -> "d-m-Y"
        dateFormat: this.flatpickrFormatMap[this.dateFormat],
        // Optional: ensures the initial value is displayed
        defaultDate: this.selectedDate,
        // Called every time user selects a date
        onChange: this.testfun,
      };
    },
  },

  watch: {
    // If the parent updates 'modelValue', sync to 'selectedDate'
    modelValue: {
      immediate: true,
      handler(newValue) {
        if (newValue !== this.selectedDate) {
          this.selectedDate = newValue;
        }
      },
    },
  },

  methods: {
    testfun() {
      // If user changes date in Flatpickr, emit to parent
      if (this.selectedDate !== null) {
        this.$emit('update:modelValue', this.selectedDate);
        this.$emit('focusoutfun');
      }
    },
  },
};
</script>

<style scoped>
.active {
  opacity: 1;
  pointer-events: auto;
  position: relative !important;
}
.baseinput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
}
.labelstyle {
  font-size: 14px;
  margin-bottom: 0.1rem !important;
  font-weight: 600;
}
.minwidth {
  min-width: max-content;
}
.errorlist {
  border-width: 2px !important;
  border-color: red !important;
}
.hightlight {
  border-bottom: 6px solid #f8ac59;
}
.imp {
  color: #ff0000;
  font-size: 7px;
}
.borderValidated {
  border: 2px solid green !important;
}
.borderIncorrect {
  border: 2px solid orange !important;
}
.borderFail {
  border: 2px solid red !important;
}
</style>
