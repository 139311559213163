<template>
  <div class="stat-card-white" ref="captureArea">
    <div class="stat-header-white">
      <span>{{ chartlabel }}</span>
      <div v-show="!loader" class="d-flex align-items-center">
        <div class="expand-block px-2 mb-1 cursor-pointer position-relative">
          <img @click="showtable()" src="../../assets/img/table_view.png" alt="More block" />
          <div class="more-expand">
            <span>{{$t("navigations.data overview")}}</span>
          </div>
        </div>
        <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock = true">
          <img src="../../assets/img/expand.png" alt="Expand block" />
        </div>
        <div class="text-center" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="exporttoexcell()">Export to Excel</div>
            <div @click.prevent="exporttopdf()">Export to PDF</div>
          </div>
        </div>
      </div>
    </div>

    <div class="Newstat-body-white" ref="chartContainerBlock">
      <div v-if="loader" class="loader"></div>
      <div v-show="!loader">
        <svg ref="chartContainer"></svg>
        <div v-if="colorcodes.length!=0" class="d-flex">
      <div v-for="colors in colorcodes" :key="colors.type" class="mx-auto d-flex">
        <div class="legandbox" :style="`background-color: ${colors.color};`"></div>
        {{ colors.type }}
      </div>
    </div>
        <div ref="tooltip" class="tooltip"></div>
      </div>
    </div>
    
  </div>
</template>

<script>
import * as d3 from "d3";
import html2pdf from 'html2pdf.js';
export default {
  name: "LineChart",
  props: {
    data: {
      type: Array,
      required: false,
      default: null,
    },
    chartlabel: {
      type: String,
      default: "Interactive Analysis",
    },
    loader: {
      type: Boolean,
      default: false,
    },
    graphwidth: {
      type: Number,
      default: 1200,
    },
    graphheight: {
      type: Number,
      default: 400,
    },
    colorcodes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartwidth:1200,
      chartContainer: null,
      mouseX: 0,
      mouseY: 0,
      showVisitPopup: false,
    };
  },
  watch: {
    data: {
      handler(newData) {
        if (newData && newData.length > 0) {
          this.createChart();
        } else {
          this.clearChart();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    window.addEventListener('mousemove', this.updateMousePosition);
    this.chartContainer = this.$refs.chartContainer;
    this.chartwidth = this.graphwidth;
    this.resizeObserver = new ResizeObserver(entries => {
    for (let entry of entries) {
      console.log("inside resizeobserver");
      const width = entry.contentRect.width;
      this.chartwidth = width; // Dynamically adjust the graph width
      this.createChart(); // Re-render the chart
    }
  });

  if (this.$refs.captureArea) {
    this.resizeObserver.observe(this.$refs.captureArea);
  }
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.updateMousePosition);
  },
  methods: {
    backtooriginal() {
      this.sortdata = [];
      this.selectedGroups = []; // Reset selection
    },
    showtable() {
      this.showMore = false
      this.$emit("opendrillpopup", this.data);
    },
    async exporttoexcell() {
      this.$emit("exporttoexcell");
    },
    exporttopdf() {
      // Create a temporary container
      const excludeElement = this.$refs.excludeThis;
      excludeElement.style.display = 'none';
      const pdfContainer = document.createElement('div');

      const captureArea = this.$refs.captureArea.cloneNode(true);
      // Append cloned elements to the container
      pdfContainer.appendChild(captureArea);
      
      // Optionally style the container for PDF formatting
     
      pdfContainer.style.width = '100%';

      pdfContainer.style.display = 'block';
      // Set the options for html2pdf
      const opt = {
        margin: 0,
        filename: `${this.chartlabel}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };
    
      // Convert the temporary container to PDF
      html2pdf().set(opt).from(pdfContainer).save();
    },
    updateMousePosition(event) {
      // Get the bounding rectangle of the component
      if (this.$refs.chartContainer) {
        const rect = this.$refs.chartContainer.getBoundingClientRect();

        // Calculate the mouse X and Y positions relative to the component
        this.mouseX = event.clientX - rect.left;
        this.mouseY = event.clientY - rect.top;
      }
      else {
        return
      }

    },
    createChart() {
      const margin = { top: 20, right: 30, bottom: 60, left: 60 };
      const width = this.chartwidth - margin.left - margin.right;
      const height = this.graphheight - margin.top - margin.bottom;

      // Clear any existing content in the chart container
      d3.select(this.chartContainer).selectAll("*").remove();

      // Aggregate the data for both lines
      const line1Data = this.aggregateDataLine1(this.data);
      const line2Data = this.aggregateDataLine2(this.data);

      if (line1Data.length === 0 || line2Data.length === 0) {
        console.warn("No data available for chart.");
        return;
      }

      // Create scales
      const xScale = d3
        .scalePoint()
        .domain(line1Data.map((d) => d.key))
        .range([0, width])
        .padding(0.5);

      const yScale = d3
        .scaleLinear()
        .domain([0, d3.max([...line1Data, ...line2Data], (d) => d.value)])
        .range([height, 0]);

      // Create SVG element
      const svg = d3
        .select(this.chartContainer)
        .attr("width", this.chartwidth)
        .attr("height", this.graphheight);

      const chart = svg
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

      // Create X axis
      chart
        .append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(xScale))
        .selectAll("text")
        .attr("transform", "rotate(-45)")
        .style("text-anchor", "end");

      // Create Y axis
      chart.append("g").call(d3.axisLeft(yScale));

      // Line generator for both lines
      const lineGenerator = d3
        .line()
        .x((d) => xScale(d.key))
        .y((d) => yScale(d.value))
        .curve(d3.curveMonotoneX);

      // Append first line
      chart
        .append("path")
        .datum(line1Data)
        .attr("fill", "none")
        .attr("stroke", "#FFA500")
        .attr("stroke-width", 2)
        .attr("d", lineGenerator);

      // Append circles for first line
      chart
        .selectAll(".dot-line1")
        .data(line1Data)
        .enter()
        .append("circle")
        .attr("class", "dot-line1")
        .attr("cx", (d) => xScale(d.key))
        .attr("cy", (d) => yScale(d.value))
        .attr("r", 4)
        .attr("fill", "#FFA500");

      // Append second line
      chart
        .append("path")
        .datum(line2Data)
        .attr("fill", "none")
        .attr("stroke", "#D32F2F")
        .attr("stroke-width", 2)
        .attr("d", lineGenerator);

      // Append circles for second line
      chart
        .selectAll(".dot-line2")
        .data(line2Data)
        .enter()
        .append("circle")
        .attr("class", "dot-line2")
        .attr("cx", (d) => xScale(d.key))
        .attr("cy", (d) => yScale(d.value))
        .attr("r", 4)
        .attr("fill", "#D32F2F");

      // Add tooltip
      const tooltip = d3
        .select(this.$refs.tooltip)
        .style("opacity", 0)
        .style("position", "absolute")
        .style("background", "black")
        .style("border", "1px solid black")
        .style("padding", "5px");

      // Tooltip for first line
      chart
        .selectAll(".dot-line1")
        .on("mouseover", (event, d) => {
          tooltip
            .style("opacity", 1)
            .html(`SITEID: ${d.key}<br> Average AEs per Patient at Site: ${d.value.toFixed(2)}`);
        })
        .on("mousemove", (event) => {
          tooltip
            .style("left", `${this.mouseX + 10}px`)
            .style("top", `${this.mouseY - 20}px`);
        })
        .on("mouseout", () => {
          tooltip.style("opacity", 0);
        });

      // Tooltip for second line
      chart
        .selectAll(".dot-line2")
        .on("mouseover", (event, d) => {
          tooltip
            .style("opacity", 1)
            .html(`SITEID: ${d.key}<br>Average SAE per Patient at Site: ${d.value.toFixed(2)}`);
        })
        .on("mousemove", (event) => {
          tooltip
            .style("left", `${this.mouseX + 10}px`)
            .style("top", `${this.mouseY - 20}px`);
        })
        .on("mouseout", () => {
          tooltip.style("opacity", 0);
        });
        // X-Axis Label
      svg
        .append("text")
        .attr("x", width / 2)
        .attr("y", height + margin.bottom +10)
        .attr("text-anchor", "middle")
        .style("font-size", "12px")
        .text("Sites");

      // Y-Axis Label
      svg
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("x", -(height / 2))
        .attr("y", 20)
        .attr("text-anchor", "middle")
        .style("font-size", "12px")
        .text("Average AE/SAE per Patient count");
    },
    clearChart() {
      d3.select(this.chartContainer).selectAll("*").remove();
    },
    aggregateDataLine1(data) {
      if (!data || data.length === 0) return [];

      // Group data by SITEID
      const groupedData = d3.group(data, (d) => d.SITEID);

      // Calculate the ratio for each SITEID
      return Array.from(groupedData, ([key, values]) => {
        const uniqueUSUBJIDs = new Set(values.map((d) => d.USUBJID)).size;
        const totalEntries = values.length;
        return {
          key,
          value: totalEntries/uniqueUSUBJIDs,
        };
      });
    },
    aggregateDataLine2(data) {
      if (!data || data.length === 0) return [];

      // Group data by SITEID
      const groupedData = d3.group(data, (d) => d.SITEID);

      // Calculate the ratio for each SITEID
      return Array.from(groupedData, ([key, values]) => {
        const uniqueUSUBJIDs = new Set(values.map((d) => d.USUBJID)).size;
        const totalYesValues = values.filter((d) => d.AEYN === "Yes").length;
        return {
          key,
          value: totalYesValues/uniqueUSUBJIDs,
        };
      });
    },
  },
};
</script>

<style scoped>
@import "../../custom_lib/AiCharts/charts.css";
.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.Newstat-body-white {
  justify-content: center;
  align-items: center;
  padding: 18px 10px;
}
.legandbox {
  width: 15px;
  height: 15px;
  margin-right: 3px;
}
svg {
  font-family: Arial, sans-serif;
}
</style>
