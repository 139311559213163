/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import { EventBus } from '../ToCgenerate/tocgenerate.vue';
export default {
  name: "stats",
  components: {
    Multiselect,
  },
  props:{
    id:{
      type:String,
      default:""
    }
  },
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      programName: "",
      rowFieldName: "",
      groupByField: "",
      returnType: "",
      orientation: "",
      pageGroup: "",
      reportName:"",
      reportId:"",
      developerName:"",
      qcName:"",
      status:"",
      programId:"",
      tocId:"",
      rowFilterFields: [],
      columnFilters: [],
      data: [],
      loadingBlock: false,
      loadingSaveBlock:false,
      rowFilterValidations: [],
      dataPages: [],
      numericOperations:[],
      numericOperationValues:[
        {label:"count",value:"count"},
         {label:"mean",value:"mean"},
        {label:"std",value:"std"},
        {label:"min",value:"min"},
        {label:"max",value:"max"}
      ],
      calculationsFilterValidations: [],
      selectedFields: [""],
      rowFilters: [{
        Operator: "",
        ColumnName: "",
        Value: ""
      }
      ],
      calculations: {
      },
      columnFieldNames: [],
      groupByFilter: [],
      chartType: "",
      studyInfo:{},
      reportType: "",
      graphData: "",
      clickedTab: "",
      footnotes: "",
      displayModal: false,
      showCreate:false,
      counter: 1,
      programName:"",
      tableTitle: "",
      tableSubtitle: "",
      tableGroupBy: [],
      tableRowFilters:[{
        Operator: "",
        ColumnName: "",
        Value: ""
      }],
      tableColumnFieldNames:[],
      tableRowFieldNames:[],
      tableColumnFilters:[],
      tablePageGroup:"",
      tableReturnType:"",
      tableChartType:"",
      tableReportType:"",
      tableOrientation:"",
      validations: [false, false, false, false, false, false, false, false, false, false, false, false, false],
      fileName: "",
      fileList: ["LocalStorage/adsl.xlsx", "LocalStorage/adae.xlsx"]
    }
  },
  created() {
    // Listen for the event and update the data
    this.$eventBus.on('data-sent', (childlabels) => {
     
      
    });
  },
  mounted() {
    this.reportName=localStorage.getItem("Reportname")
    this.reportId=localStorage.getItem("Reportid")
    this.tocId=localStorage.getItem("TocId")
    this.getstudyinfo()
    const key = "frequency_fields"; // This can be dynamic
    const value = {
      group_by: [],
      frequency_column: [],
      subject_column: []
    };

    // Add the key-value pair to the object
    this.calculations[key] = value;
    // await this.getFiles()
  },
  created() {
    const savedState = localStorage.getItem('showTocCreate');
    if (savedState == 'true' || savedState == true) {
      this.showCreate = true; // Set state to true if saved in localStorage
    } else {
      this.showCreate = false; // Default to false if nothing is in localStorage
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log("from",from.name)
    if (from.name == 'tocgenerate') {
      localStorage.setItem('showTocCreate', 'true');
    } 
    next((vm) => {
      const savedState = localStorage.getItem('showTocCreate');
      if (savedState == 'true' || savedState == true) {
        vm.showCreate = true;
      }
    });
  },
  beforeRouteLeave(to, from, next) {
      localStorage.removeItem('showTocCreate');
      localStorage.removeItem('Reportname');
      localStorage.removeItem('Reportid');
      localStorage.removeItem('TocId');
      next();
    
  },
  methods: {
    async getFields() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/stats-python/programme/fields?fileName=${this.fileName}&threshold=10`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.rowFilterFields = [...res.data.categorical, ...res.data.numerical].sort((a, b) => {
            if (a < b) return -1;
            if (a > b) return 1;  
            return 0;            
          });
          this.columnFilters = [...res.data.categorical, ...res.data.numerical].sort((a, b) => {
            if (a < b) return -1; 
            if (a > b) return 1;  
            return 0;             
          });
          this.groupByFilter = res.data.categorical.sort((a, b) => {
            if (a < b) return -1; 
            if (a > b) return 1; 
            return 0;
          });
          console.log(this.rowFilterFields);

        })
        .catch((err) => {
          console.log(err);

        });

    },
    // checkFields(){
    //   if(this.rowFilterFields.length==0 && this.columnFilters.length==0 && this.groupByFilter.length==0){
    //     alert(this.$t("navigations.please select a file"))
    //   }
    // },
    // async getFiles() {
    //   const idtoken = store.getters.getIdToken;
    //   await axios
    //     .get(
    //       `${this.baseurl}/stats-python/programme/fields?file=${this.fileName}&threshold=10`,
    //       {
    //         headers: {
    //           Authorization: "Bearer " + idtoken,
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       this.fileList=res.data

    //     })
    //     .catch((err) => {
    //       console.log(err);

    //     });

    // },   
    async addRow(type, index) {
      if (type == 'row') {
        this.rowFilters.push(
          {
            Operator: "",
            ColumnName: "",
            Value: ""
          }
        )
      }
      else if (type == 'calculations') {
        // Create a unique key like 'frequency_fields1', 'frequency_fields2', etc.
        const key = `frequency_fields`;
        // Add the key with the default object structure
        this.calculations[key] = {
          group_by: [],
          frequency_column: [],
          subject_column: []
        }
      }
      // else if (type == 'footnotes'){
      //   this.footnotes.push(
      //     ""
      //   )
      // }
      else {
        this.rowTableFilters.push(
          {
            Operator: "",
            ColumnName: "",
            Value: ""
          }
        )
      }
    },
    async deleteFilter(type, index) {
      if (type == 'row') {
        this.rowFilters.splice(index, 1);
      }
      else if (type == 'calculations') {
        delete this.calculations[index];
        this.counter--
      }
      // else if (type == 'footnotes'){
      //   this.footnotes.splice(index)
      // }
      else
      {
        this.rowTableFilters.splice(index, 1);
      }

    },
    async setValidations() {
      if (this.rowFilters.length > 0) {
        this.rowFilters.forEach((item, index) => {
          this.rowFilterValidations[index] = [false, false, false]
          if (item.ColumnName == "" || item.ColumnName == undefined) {
            this.rowFilterValidations[index][0] = true
          }
          if (item.Operator == "" || item.Operator == undefined) {
            this.rowFilterValidations[index][1] = true

          }
          if (item.Value == "" || item.Value == undefined) {
            this.rowFilterValidations[index][2] = true
            this.loadingBlock = false
          }
        })
      }
      if (this.calculations.length > 0) {
        this.calculations.forEach((item, index) => {
          this.calculationsFilterValidations[index] = [false, false, false]
          if (item.group_by == "" || item.group_by == undefined) {
            this.calculationsFilterValidations[index][0] = true
          }
          if (item.frequency_column == "" || item.frequency_column == undefined) {
            this.calculationsFilterValidations[index][1] = true

          }
          if (item.subject_column == "" || item.subject_column == undefined) {
            this.calculationsFilterValidations[index][2] = true
            this.loadingBlock = false
          }
        })
      }
      const fields = [
        { value: this.programName, index: 0 },
        { value: this.fileName, index: 1 },
        { value: this.columnFieldNames.length > 0, index: 2 },
        { value: this.groupByField, index: 3 },
        { value: this.pageGroup, index: 4 },
        { value: this.returnType, index: 5 },
        { value: this.orientation, index: 6 },
        { value: this.reportType, index: 7 },
        { value: this.chartType, index: 8 },
        { value: this.tableTitle, index: 9 },
        { value: this.tableSubtitle, index: 10 },
        { value: this.tableSeverity, index: 11 },
        { value: this.tableGroupBy, index: 12 }
      ];

      fields.forEach(({ value, index }) => {
        this.validations[index] = !value; // Set true if the value is falsy
      });
      if (this.validations.includes(true) || this.rowFilterValidations.some((error) => error.includes(true)) || this.calculationsFilterValidations.some((error) => error.includes(true))) {
        this.loadingBlock = false
      }
    },
    async exBtnClicked() {
      await this.getGraphData()
      await this.$nextTick();
      this.dataPages.forEach((eachitem,index) => {
         this.loadIframeContent(`${eachitem.Page}-${index}`, eachitem.Value)
      })
    },
    async getGraphData() {
      this.loadingBlock = true
      // await this.setValidations()
      // if (!this.validations.includes(true) && !this.rowFilterValidations.some((error) => error.includes(true))) {
      const idtoken = store.getters.getIdToken;
      await this.updateArray()
      await axios
        .post(
          `${this.baseurl}/stats-python/programme/execute`,
          {

            "ProgramName": this.programName,
            "FileName": this.fileName,
            "RowFilters": this.rowFilters,
            "ColumnFilters":
              this.columnFieldNames
            ,
            "ReportType": this.reportType,
            "GroupBy": this.groupByField,
            "IncludeTotal": true,
            "NumericOperations":this.numericOperations,
            "PageGroup": this.pageGroup,
            "ReturnType": this.returnType,
            "Orientation": this.orientation,
            "Projection": "",
            "Sorting": {
            },
            "Table": this.reportType=='Table' ? [
              {  
                "ProgramName": this.programName,
                "RowFields": this.tableRowFieldNames,
                "ColumnFields":this.tableColumnFieldNames,
                "ColumnFilters":this.columnTableFilters,
                "RowFilters": this.rowTableFilters,
                "GroupBy": this.tableGroupBy,
                "IncludeTotal": true,
                "PageGroup": this.tablePageGroup,
                "ReturnType": this.returnType,
                "ReportType": this.reportType,
                "Orientation": this.orientation,
                "Title": this.tableTitle,
                "Subtitle": this.tableSubtitle,
                "Footnote":this.footnotes,
                "CreatedTime": "2025-01-09T12:00:00Z"
              }
            ]:[]
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.dataPages = res.data;
          this.dataPages.forEach((eachitem) => {
            eachitem.Value = this.hexToUtf8(eachitem.Value);
          })

          console.log("this.dataPages", this.dataPages);
          this.displayModal = true
          this.loadingBlock = false

        })
        .catch((err) => {
          this.loadingBlock = false
          this.validations = [false, false, false, false, false, false, false]

        });

    },
    async getstudyinfo() {
      const idtoken = store.getters.getIdToken;
          await axios
        .get(
          `${this.baseurl}/management/study/getbyid/${store.getters.getStudyIs}?api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.studyInfo = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      },
    async saveData() {
      this.loadingSaveBlock = true
      const idtoken = store.getters.getIdToken;
      await this.updateArray()
      await axios
        .post(
          `${this.baseurl}/stats-python/programme/createtocprogram`,
          {
            "StudyId":this.studyInfo.studyId,
            "StudyName": this.studyInfo.studyName,
            "StudyRef": this.studyInfo.studyRef,
            "TocId": this.tocId,
            "ReportId": this.reportId,
            "ReportName": this.reportName,
            "Version": "",
            "TocDescription": "",
            "DeveloperName": this.developerName,
            "QCName":this.qcName,
            "Status": this.status,
            "ProgramId": this.programId,
            "ProgramName": this.programName,
            "FileName": this.fileName,
            "RowFilters": this.rowFilters,
            "ColumnFilters":
              this.columnFieldNames
            ,
            "ReportType": this.reportType,
            "GroupBy": this.groupByField,
            "IncludeTotal": true,
            "NumericOperations": this.numericOperations,
            "PageGroup": this.groupByField,
            "ReturnType": this.returnType,
            "Orientation": this.orientation,
            "Projection": "",
            "Sorting": {
            },
            "Table": this.reportType=='Table' ? [
              {  
                "ProgramName": this.programName,
                "RowFields": this.tableRowFieldNames,
                "ColumnFields":this.tableColumnFieldNames,
                "ColumnFilters":this.columnTableFilters,
                "RowFilters": this.rowTableFilters,
                "GroupBy": this.tableGroupBy,
                "IncludeTotal": true,
                "PageGroup": this.tablePageGroup,
                "ReturnType": this.returnType,
                "ReportType": this.reportType,
                "Orientation": this.orientation,
                "Title": this.tableTitle,
                "Subtitle": this.tableSubtitle,
                "Footnote":this.footnotes,
                "CreatedTime": "2025-01-09T12:00:00Z"
              }
            ]:[]
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.loadingSaveBlock=false
          alert(this.$t('created successfully'))
        })
        .catch((err) => {
          this.loadingSaveBlock = false
        });

    },
    adjustIframeWidth(event,page) {
      const iframe = document.getElementById(page);
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

      if (iframeDocument && iframeDocument.body) {
        // Get the content's width
        const contentWidth = iframeDocument.body.scrollWidth;
        if(contentWidth>300){
          iframe.style.width = `${contentWidth}px`;
        }
        
      }
    },
    loadIframeContent(page, value) {
      const iframe = document.getElementById(page);
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.open();
      doc.write(value);
      doc.close();
    },
    hexToUtf8(hexString) {
      // Ensure the hex string is properly formatted by removing spaces and any extra characters
      hexString = hexString.replace(/\s+/g, '');

      // Convert each pair of hex digits to a character and join them into a string
      let utf8String = '';
      for (let i = 0; i < hexString.length; i += 2) {
        const hexCode = hexString.substr(i, 2);
        utf8String += String.fromCharCode(parseInt(hexCode, 16));
      }

      return utf8String;
    },
    async updateArray() {
      if (this.rowFilters.length > 0) {
        this.rowFilters.forEach((item) => {
          if (item.Value) {
            if (!isNaN(item.Value)) {
              if (item.Value && String(item.Value).trim() !== '') {
                item.Value = parseFloat(item.Value); // Parse to number
              }
            } else {
              item.Value = item.Value.toString(); // Keep as string
            }
          }
        })
      }
    },
  }
}
