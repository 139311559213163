 <template>
  <!-- modal starts -->
  <div class="iframe-wrapper">
    <div class="iframe-container">
      <div class="titleclass d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">{{ $t("navigations.map macro") }}</h4>
        <a class="close-link">
          <i class="fa fa-times" @click.prevent="closeModal"></i>
        </a>
      </div>
      <div class="iframe-body width-height p-4">
        <tbody>
          <tr v-for="pgmList in programme" :key="pgmList._id">
            <button class="btn btn-primary" @click="mapProgramme(pgmList)">
              {{ pgmList.ProgramName }}
            </button>
          </tr>
        </tbody>
      </div>
    </div>
  </div>
</template>
<script src="./mapProgrammePopup.js"></script>
<style scoped>
.multiselect-theme {
  --ms-radius: 0;
  --ms-py: 0.4rem;
  --ms-option-font-size: 0.8rem;
  --ms-option-bg-selected-pointed: rgb(210, 210, 210);
  --ms-option-bg-selected: rgb(210, 210, 210);
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
}

.important-block {
  color: rgb(255, 0, 0);
  font-size: 7px;
}

.iframe-wrapper {
  position: fixed;
  display: grid;
  place-items: center;
  inset: 0;
  background: rgb(0 0 0 / 30%);
  transition: cubic-bezier;
  overflow: hidden;
  z-index: 10000;
}

.iframe-container {
  width: min(700px, 90%);
  background: #fff;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.loading-btn-img {
  display: block;
  mix-blend-mode: lighten;
}

.disabled-btn-cls {
  opacity: 0.6;
  pointer-events: none;
}

.repeat-border-bottom {
  border-bottom: 1px dashed hsl(237, 42%, 40%);
}

.iframe-block {
  width: 100%;
  height: 500px;
}

.width-height {
  height: 500px;
  overflow: auto;
}

.toc-loader {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}
</style>